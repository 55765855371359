/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'

// Icon
import favicon from 'img/favicon.png'

const removeHTML = (content) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

const SEO = ({ seo, socialImage }) => {
  const {
    site,
    wpComponent,
    // eslint-disable-next-line
  } = useStaticQuery(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const defaultImageUrl = socialImage
    ? {
        url: `${site.siteMetadata.siteUrl}${socialImage}`,
      }
    : ''

  const image = defaultImageUrl

  console.log(seo, 'seo')

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: removeHTML(seo.title) || '',
          description: removeHTML(seo.metaDesc) || '',
          images: [image],
        }}
      />
      <Helmet>
        {/* Global site tag (gtag.js) - Google Marketing Platform */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=DC-12400670"
        />
        <script>
          {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'DC-12400670');
                `}
        </script>
        <script>
          {`
                  gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': 'DC-12400670/pagev0/homep0+standard'
                  });
                `}
        </script>
        <noscript>
          {`
                <img src="https://ad.doubleclick.net/ddm/activity/src=12400670;type=pagev0;cat=homep0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
                `}
        </noscript>
        
        <script>
          {`
(function(d,a,t,h,u,i,s){d["DatHuisObject"]=u;d[u]=d[u]||function(){(d[u].q=d[u].q||[]).push(arguments)};d[u].l=1*new Date;se=a.createElement(t);fs=a.getElementsByTagName(t)[0];se["async"]=1;se.src=h;fs.parentNode.insertBefore(se,fs)})(window,document,"script","https://btstrp.dathuis.nl/assets/widget.js","dhuw");
dhuw('init', { accountId: 'a---9ff94c38-cda3-4fd1-b3b4-0ae9e95d3da8' });
`}
</script>
        {/* End of event snippet: Please do not remove */}
      </Helmet>
    </>
  )
}

export default SEO
